<template>
  <v-dialog v-model="visible" max-width="300" persistent>
    <v-card>
      <v-card-title class="headline"> Answers Selected </v-card-title>
      <v-card-text>
        <v-row v-if="right.length > 0" class="mb-0 pb-0" align="center">
          <v-col cols="3" class="mb-0 pb-0">
            <v-icon class="ml-2 mr-2" color="green">mdi-check-circle</v-icon>
          </v-col>
          <v-col cols="7" class="mb-0 pb-0 wordText">
            {{ right.join(', ') }}
          </v-col>
        </v-row>

        <v-row v-if="wrong.length > 0" class="mb-0 pb-0" align="center">
          <v-col cols="3" class="mb-0 pb-0">
            <v-icon class="ml-2 mr-2" color="red">mdi-close-circle</v-icon>
          </v-col>
          <v-col cols="9" class="mb-0 pb-0 wordText">
            {{ wrong.join(', ') }}
          </v-col>
        </v-row>
      </v-card-text>

      <div v-if="notSelectedAnswers.length > 0">
        <v-card-title class="headline font-weight-medium">
          Answers Not Selected
        </v-card-title>
        <v-card-text>
          <v-row class="mb-0 pb-0" align="center">
            <v-col cols="3" class="mb-0 pb-0">
              <v-icon class="ml-4 mr-2" color="green">mdi-check-circle</v-icon>
            </v-col>
            <v-col cols="9" class="mb-0 pb-0 wordText">
              {{ notSelectedAnswers.join(', ') }}
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="show = false"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    visible: { type: Boolean, default: false, required: true },
    right: { type: Array, required: true },
    wrong: { type: Array, required: true },
    notSelectedAnswers: { type: Array, required: true }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
<style scoped>
.wordText {
  text-shadow: 0 1px 1px #eee;
  font-size: 20px;
  line-height: 1.8rem;
  color: rgba(0, 0, 0, 0.6);
}
</style>
