<template>
  <div>
    <span
      :style="{ color: $vuetify.theme.themes.light.primary }"
      class="text-h6 mr-3"
      >Your skill level is:
    </span>
    <v-icon v-for="(i, k) in skillRating" :key="'star' + k" :color="i.color">
      {{ i.icon }}
    </v-icon>
  </div>
</template>
<script>
export default {
  props: ['score'],
  mounted() {
    this.calculateScore()
  },
  methods: {
    calculateScore() {
      this.skillLevelDialog = true
      let score = this.score.total - this.score.wrong
      this.skillRating = []
      while (score >= this.score.total / 5) {
        this.skillRating.push({
          icon: 'mdi-star',
          color: this.$vuetify.theme.themes.light.secondary
        })
        score = score - this.score.total / 5
      }
      if (score >= this.score.total / 10) {
        this.skillRating.push({
          icon: 'mdi-star-half-full',
          color: this.$vuetify.theme.themes.light.secondary
        })
      }
      while (this.skillRating.length < 5) {
        this.skillRating.push({ icon: 'mdi-star-outline', color: '' })
      }
    }
  },
  watch: {
    score: function (newValue) {
      this.calculateScore()
    }
  },
  data: () => ({
    skillRating: []
  })
}
</script>
