<template>
    <div
        @mousedown="touchStart($event)"
        @mouseup="touchEnd($event)"
        @mouseleave="touchEnd($event)"
        @mouseout="touchEnd($event)"
        @touchstart="touchStart(word,$event)"
        @touchend="touchEnd($event)"
        @touchcancel="touchEnd($event)">
            <slot>

            </slot>
            <WordHelpDialog  v-if="showWordHelpDialog" :visible="showWordHelpDialog" :word="word"  @close="showWordHelpDialog=false"></WordHelpDialog>

    </div>

</template>

<script>
import WordHelpDialog from './WordHelpDialog'

export default {

  props: {
    word: { type: Object, required: true }
  },
  components: {
    WordHelpDialog
  },
  data () {
    return {
      touchInterval: false,
      touchTimer: 0,
      showWordHelpDialog: false
    }
  },

  methods: {
    touchStart (event) {
      if (!this.touchInterval) {
        this.touchInterval = setInterval(() => {
          this.touchTimer++
          if (this.touchTimer === 10) {
            event.preventDefault()
            this.showWordHelpDialog = true
            clearInterval(this.touchInterval)
            this.touchTimer = 0
            this.touchInterval = false
          }
        }, 30)
      }
      if (this.showWordHelpDialog) {
        event.preventDefault()
      }
    },
    touchEnd (event) {
      clearInterval(this.touchInterval)
      this.touchTimer = 0
      this.touchInterval = false
      if (this.showWordHelpDialog) {
        event.preventDefault()
      }
    }
  }
}
</script>
<style>

</style>
